/*global UI */

//Basic merge element functionality
UI.MergeSourceElement = function (name, type, strategy) {
    this.HolderCell$ = null;
    this.Target = null;
    this.TargetName = name;
    this.TargetType = type;
    this.strategy = strategy;
    this.DataSet = [];
};
UI.MergeSourceElement.prototype.Init = function () {
    //Init holder
    var targetName = this.TargetName;

    if (this.strategy) {
        this.strategy.OnClickFunctions = this.OnClickFunctions;
    }

    this.Target = this.strategy.getTarget(targetName);
    this.HolderCell$ = this.strategy.getHolder(targetName);

    if (typeof (this.Target) === 'undefined') {
        throw 'No element with form name ' + targetName + ' could be found.';
    }

    if (!this.HolderCell$.length) {
        throw "mergesourceelement could not find holdercell for related input element: '#" + targetName;
    }

    this.Render();
};

UI.MergeSourceElement.prototype.Render = function () {
    if (this.DataSet.length === 0) {
        throw 'No values have been set.';
    }

    if (this.rendered) {
        return;
    }
    this.rendered = true;

    this.HolderCell$.empty();

    switch (this.TargetType) {
        case 'CheckboxList':
            for (var i = 0; i < this.DataSet.length; i++) {
                this.strategy.name = this.TargetName;
                this.strategy.render(this.DataSet[i], this.HolderCell$);
            }
            break;

        case 'RadioList':
            this.strategy.render(this.DataSet[0], this.HolderCell$);
            this.HolderCell$.addClass("s1_cell_bigpadding");
            break;

        case 'OnOff':
            this.strategy.render(this.DataSet[0], this.HolderCell$);
            this.HolderCell$.addClass("s1_cell_bigpadding");
            break;

        case 'Dropdown':
            this.strategy.render(this.DataSet[0], this.HolderCell$);
            this.HolderCell$.addClass("s1_cell_bigpadding");
            break;

        case 'Textbox':
            this.strategy.render(this.DataSet[0], this.HolderCell$);
            this.HolderCell$.addClass("s1_cell_bigpadding");
            break;

        case 'DateTime':
            this.strategy.render(this.DataSet[0], this.HolderCell$);
            this.HolderCell$.addClass("s1_cell_bigpadding");
            break;

        case 'TimeSpan':
            this.strategy.render(this.DataSet[0], this.HolderCell$);
            this.HolderCell$.addClass("s1_cell_bigpadding");
            break;

        default:
            throw 'No render implemented for: ' + this.TargetType;
    }
};

UI.MergeSourceElement.prototype.OnClick = function () {
    if (this.OnClickFunctions) {
        for (var i = 0; i < this.OnClickFunctions.length; i++) {
            /*jslint evil: true */
            eval(this.OnClickFunctions[i]);
        }
    }
};
UI.MergeSourceElement.prototype.ValidateTarget = function () {
    //empty holder to be overwritten if the target has a validationroutine
};

UI.MergeSourceStrategy = function () {

};
UI.MergeSourceStrategy.prototype.OnClick = function (onClickFunctions) {
    if (onClickFunctions) {
        for (var i = 0; i < onClickFunctions.length; i++) {
            /*jslint evil: true */
            if (typeof onClickFunctions[i] === 'function') {
                onClickFunctions[i]();
            } else {
                eval(onClickFunctions[i]);
            }
        }
    }
};
UI.MergeSourceStrategy.prototype.repaintHolderCell = function (isDifferent, holderCell$) {
    if (isDifferent) {
        holderCell$.css('background-color', 'var(--warning-background)');
        holderCell$.css('color', 'var(--warning-yellow)');
        holderCell$.css('cursor', 'pointer');
        holderCell$.css('padding-left', '6px');
    } else {
        holderCell$.css('background-color', '');
        holderCell$.css('color', '');
        holderCell$.css('cursor', '');
    }
};

// DateTime merge strategy

UI.MergeSourceStrategy.DateTime = function () {

};

UI.MergeSourceStrategy.DateTime.prototype = new UI.MergeSourceStrategy();

UI.MergeSourceStrategy.DateTime.prototype.getTarget = function (targetName) {

    /*jslint evil: true */
    this.Target = eval(targetName);
    // console.log("GetTarget", targetName, this.Target);
    return this.Target;
};

UI.MergeSourceStrategy.DateTime.prototype.getHolder = function (targetName) {
    return $('#' + targetName + 'HolderDiv').closest('tr').find('td').eq(1);
};

UI.MergeSourceStrategy.DateTime.prototype.render = function (sourceItem, holderCell$) {
    var that = this;
    var source = sourceItem;
    var isDifferent = this.checkDifference(sourceItem);
    this.holderCell$ = holderCell$;

    this.repaintHolderCell(isDifferent, holderCell$);
    holderCell$.unbind('click').click(function () {
        that.setTarget(source);
    });

    if (source.Key) {
        holderCell$.append(source.Value);
    }

    $(this.Target).unbind('change').change(function () {
        var diff = that.checkDifference(source);
        that.repaintHolderCell(diff, that.holderCell$);
    });
};

UI.MergeSourceStrategy.DateTime.prototype.checkDifference = function (sourceItem) {

    // console.log("DateTime", this);

    if (!this.Target.IsOn && (!sourceItem.Key)) {
        return false;
    }
    if (!this.Target.IsOn && sourceItem.Key) {
        return true;
    }
    if (this.Target.IsOn && !sourceItem.Key) {
        if (this.Target.hasValue()) {
            return true;
        }
        return false;
    }
    if (this.Target.hasValue() && !sourceItem.Key) {
        return true;
    }
    if (!this.Target.hasValue() && sourceItem.Key) {
        return true;
    }
    if (!this.Target.hasValue() && !sourceItem.Key) {
        return false;
    }

    return this.Target.getUnixEpoch() !== sourceItem.Key;
};

UI.MergeSourceStrategy.DateTime.prototype.setTarget = function (sourceItem) {

    if (!sourceItem.Key) {
        this.Target.SetIsOn(false);
    } else {
        this.Target.SetIsOn(true);
        this.Target.setValue(sourceItem.Key);
    }

    var isDifferent = this.checkDifference(sourceItem);
    this.holderCell$.empty();
    this.repaintHolderCell(isDifferent, this.holderCell$);

    if (sourceItem.Key) {
        this.holderCell$.append(sourceItem.Value);
    }

    this.OnClick(this.OnClickFunctions);
};

// OnOff merge strategy

UI.MergeSourceStrategy.OnOff = function () {

};

UI.MergeSourceStrategy.OnOff.prototype = new UI.MergeSourceStrategy();

UI.MergeSourceStrategy.OnOff.prototype.getTarget = function (targetName) {
    this.Target = document.getElementById(targetName);
    return this.Target;
};

UI.MergeSourceStrategy.OnOff.prototype.getHolder = function () {
    return $(this.Target).closest('tr').find('td').eq(1);
};

UI.MergeSourceStrategy.OnOff.prototype.render = function (sourceItem, holderCell$) {
    var that = this;
    var source = sourceItem;
    var isDifferent = this.checkDifference(source);
    this.holderCell$ = holderCell$;

    this.repaintHolderCell(isDifferent, this.holderCell$);
    holderCell$.click(function () {
        that.setTarget(source);
    });

    holderCell$.appendI({
        "class": (source.Checked) ? "icon-true" : "icon-false"
    });

    $(this.Target).change(function () {
        that.repaintHolderCell(that.checkDifference(source), that.holderCell$);
    });
};

UI.MergeSourceStrategy.OnOff.prototype.setTarget = function (source) {
    var isDifferent = this.checkDifference(source);
    if (isDifferent) {
        this.Target.click();
    }

    isDifferent = this.checkDifference(source);

    this.holderCell$.empty();

    this.repaintHolderCell(isDifferent, this.holderCell$);

    this.holderCell$.appendI({
        "class": (source.Checked) ? "icon-true" : "icon-false"
    });

    this.OnClick(this.OnClickFunctions);
};

UI.MergeSourceStrategy.OnOff.prototype.checkDifference = function (sourceItem) {
    return this.Target.checked !== sourceItem.Checked;
};

// TextBox merge strategy
UI.MergeSourceStrategy.TextBox = function () {

};

UI.MergeSourceStrategy.TextBox.prototype = new UI.MergeSourceStrategy();

UI.MergeSourceStrategy.TextBox.prototype.getTarget = function (targetName) {
    this.Target = document.getElementById(targetName);
    return this.Target;
};

UI.MergeSourceStrategy.TextBox.prototype.getHolder = function () {
    return $(this.Target).closest('tr').find('td').eq(1);
};

UI.MergeSourceStrategy.TextBox.prototype.render = function (sourceItem, holderCell$) {
    var that = this;
    var source = sourceItem;
    var isDifferent = this.checkDifference(source);
    this.holderCell$ = holderCell$;

    this.repaintHolderCell(isDifferent, this.holderCell$);
    holderCell$.click(function () {
        that.setTarget(source);
    });

    if (sourceItem.Value) {
        var text = soneTools.CrlfToBR(sourceItem.Value);

        this.holderCell$.append(text);
    }

    $(this.Target).keyup(function () {
        that.repaintHolderCell(that.checkDifference(source), that.holderCell$);
    });
};

UI.MergeSourceStrategy.TextBox.prototype.setTarget = function (sourceItem) {
    this.Target.value = sourceItem.Key;
    var isDifferent = this.checkDifference(sourceItem);

    soneTools.input_ClearErrorMessage(this.Target);

    this.repaintHolderCell(isDifferent, this.holderCell$);

    this.OnClick(this.OnClickFunctions);
};

UI.MergeSourceStrategy.TextBox.prototype.checkDifference = function (sourceItem) {
    if (sourceItem.Key === null && !this.Target.value) {
        return false;
    }

    return this.Target.value !== sourceItem.Key;
};

// DropDown merge strategy
UI.MergeSourceStrategy.DropDown = function () {

};

UI.MergeSourceStrategy.DropDown.prototype = new UI.MergeSourceStrategy();

UI.MergeSourceStrategy.DropDown.prototype.getTarget = function (targetName) {
    this.Target = document.getElementById(targetName);
    return this.Target;
};
UI.MergeSourceStrategy.DropDown.prototype.getHolder = function () {
    return $(this.Target).closest('tr').find('td').eq(1);
};
UI.MergeSourceStrategy.DropDown.prototype.render = function (sourceItem, holderCell$) {
    var that = this;
    var source = sourceItem;
    var isDifferent = this.checkDifference(source);
    this.holderCell$ = holderCell$;

    this.repaintHolderCell(isDifferent, this.holderCell$);
    holderCell$.click(function () {
        that.setTarget(source);
    });

    if (sourceItem.Value) {
        this.holderCell$.append(sourceItem.Value);
    }

    $(this.Target).change(function () {
        that.repaintHolderCell(that.checkDifference(source), that.holderCell$);
    });

};
UI.MergeSourceStrategy.DropDown.prototype.setTarget = function (sourceItem) {

    if (this.preClick) {
        if (this.preClick()) {
            return;
        }
    }

    if (sourceItem.Key === null) {
        this.Target.selectedIndex = 0;
    } else {
        for (var i = 0; i < this.Target.length; i++) {
            this.Target.options[i].selected = (this.Target.options[i].value.toString() === sourceItem.Key.toString());
        }
    }
    var isDifferent = this.checkDifference(sourceItem);

    soneTools.select_ClearErrorMessage(this.Target);
    $(this.Target).trigger("change");

    this.repaintHolderCell(isDifferent, this.holderCell$);
    this.OnClick(this.OnClickFunctions);
};
UI.MergeSourceStrategy.DropDown.prototype.checkDifference = function (sourceItem) {
    if (sourceItem.Key === null) {
        if (this.Target.selectedIndex === 0 || !$(this.Target).is(":visible")) {
            return false;
        } else {
            return true;
        }
    }

    return this.Target.options[this.Target.selectedIndex]?.value.toString() !== sourceItem.Key.toString();
};

// CheckBox merge strategy
UI.MergeSourceStrategy.CheckBoxList = function () {

};
UI.MergeSourceStrategy.CheckBoxList.prototype = new UI.MergeSourceStrategy();
UI.MergeSourceStrategy.CheckBoxList.prototype.getTarget = function (targetName) {
    this.Target = document.getElementsByName(targetName);
    return this.Target;
};
UI.MergeSourceStrategy.CheckBoxList.prototype.getHolder = function () {
    return $(this.Target).closest('tr').find('td').eq(1);
};
UI.MergeSourceStrategy.CheckBoxList.prototype.render = function (sourceItem, holderCell$) {
    var that = this;
    var source = sourceItem;
    var isDifferent = this.checkDifference(source);
    this.holderCell$ = holderCell$;

    var holder$ = $('<div />', {
        "id": "src_" + this.name + "_" + sourceItem.Key
    });
    holder$.css('clear', 'both');
    holder$.css('min-height', '18.8px');
    holder$.css('margin-bottom', '1px');
    holder$.css('padding-left', '3px');
    this.holderCell$.append(holder$);

    if (isDifferent) {
        holderCell$.css('padding-left', '6px');
        holder$.css('background-color', 'var(--warning-background)');
        holder$.css('color', 'var(--warning-yellow)');
        holder$.css('cursor', 'pointer');
    }

    holder$.click(function () {
        that.setTarget(sourceItem);
        that.repaintHolderCell(that.checkDifference(sourceItem), holder$);
    });

    var holderLeft$ = $('<div />', {});
    holderLeft$.css('float', 'left');
    holderLeft$.css('margin-right', '3px');
    holder$.append(holderLeft$);

    holderLeft$.appendI({
        "class": (source.Checked) ? "icon-true" : "icon-false"
    });

    var holderRight$ = $('<div />', {});
    holderRight$.css('float', 'left');
    holder$.append(holderRight$);

    holderRight$.append(sourceItem.Value);
    this.setTargetClick(sourceItem, holder$);
};
UI.MergeSourceStrategy.CheckBoxList.prototype.setTargetClick = function (sourceItem, holder$) {
    console
    var that = this;
    var clickFunction = function () {
        that.repaintHolderCell(that.checkDifference(sourceItem), holder$);
    };

    for (var i = 0; i < this.Target.length; i++) {
        if (this.Target[i].value.toString() === sourceItem.Key.toString()) {
            $(this.Target[i]).unbind('click').click(clickFunction);
        }
    }
};
UI.MergeSourceStrategy.CheckBoxList.prototype.setTarget = function (sourceItem) {
    for (var i = 0; i < this.Target.length; i++) {
        if (this.Target[i].value.toString() === sourceItem.Key.toString()) {
            $(this.Target[i]).prop('checked', sourceItem.Checked);
            this.repaintHolderCell(false, this.holderCell$);
            this.OnClick(this.OnClickFunctions);
        }
    }
};
UI.MergeSourceStrategy.CheckBoxList.prototype.checkDifference = function (sourceItem) {
    for (var i = 0; i < this.Target.length; i++) {
        if (this.Target[i].value.toString() === sourceItem.Key.toString()) {
            return (this.Target[i].checked !== sourceItem.Checked);
        }
    }
    return false;
};
// RadioList merge strategy
UI.MergeSourceStrategy.RadioList = function () {

};
UI.MergeSourceStrategy.RadioList.prototype = new UI.MergeSourceStrategy();
UI.MergeSourceStrategy.RadioList.prototype.getTarget = function (targetName) {
    this.targetName = targetName;
    this.Target = document.getElementsByName(targetName);
    return this.Target;
};
UI.MergeSourceStrategy.RadioList.prototype.getHolder = function () {
    return $(this.Target).closest('tr').find('td').eq(1);
};
UI.MergeSourceStrategy.RadioList.prototype.render = function (sourceItem, holderCell$) {
    var that = this;
    var source = sourceItem;
    var isDifferent = this.checkDifference(source);
    this.holderCell$ = holderCell$;

    this.repaintHolderCell(isDifferent, this.holderCell$);
    holderCell$.click(function () {
        that.setTarget(source);
    });

    $(this.Target).change(function () {
        that.repaintHolderCell(that.checkDifference(source), that.holderCell$);
    });

    this.holderCell$.append(sourceItem.Value);

};
UI.MergeSourceStrategy.RadioList.prototype.setTarget = function (sourceItem) {

    for (var i = 0; i < this.Target.length; i++) {
        if (this.Target[i].value.toString() === sourceItem.Key.toString()) {
            this.Target[i].click();
            soneTools.cbList_ClearErrorMessage(this.targetName);
            this.repaintHolderCell(false, this.holderCell$);
            this.OnClick(this.OnClickFunctions);
        }
    }

};
UI.MergeSourceStrategy.RadioList.prototype.checkDifference = function (sourceItem) {
    for (var i = 0; i < this.Target.length; i++) {
        if (this.Target[i].value.toString() === sourceItem.Key.toString()) {
            return !this.Target[i].checked;
        }
    }
    return false;
};