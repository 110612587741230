var moment = require("moment");
import {
    formatShortDate
} from "../../typescript/react/utilities/momentutils";

$.widget("sone.linkeddocumentspreview", {
    options: {

    },
    _create: function () {
        this._super();
    },
    openDialog: function (obj) {
        var that = this;
        that._queryServer(obj.documentType, obj.showId);
    },
    _queryServer: function (documentType, showId) {
        var that = this;

        window.CoreApi.Document.ListDocumentsByShow({
            showId,
            documentType,
        }).then((response) => {
            that._openDialog(site.currentEnvironment[documentType].plural, response.data);
        });
    },
    _openDialog: function (title, collection) {
        var dialog$ = $("<div />", {
            title: title
        });

        this._appendTable(dialog$, collection);

        dialog$.dialog2({
            autoOpen: true,
            width: 1000,
            autoHeight: true,
            closeOutside: true
        });
    },
    _appendTable: function (dialogDiv$, collection) {
        var that = this;

        var table$ = dialogDiv$.appendTable({
            "class": "s1_table list"
        });

        var tbody$ = table$.appendTbody();

        $.each(collection, function () {
            that._appendBodyRow(tbody$, this);
        });
    },
    _appendBodyRow: function (tbody$, item: server.dto.DocumentForList) {
        var tr$ = tbody$.appendTr();

        this._addRocketColumn(tr$, item);
        this._appendTitle(tr$, item);
        this._appendAddressee(tr$, item);
        this._appendSendDueDate(tr$, item);
        this._appendTotalCell(tr$, item);
    },
    _addRocketColumn: function (tr$, item: server.dto.DocumentForList) {

        const td$ = $("<td />",
            {
                style: "white-space:nowrap;width:30px",

            });
        tr$.append(td$);

        if (!item.legacy) {
            td$.append($("<i />",
                {
                    "class": "icon-rocket",
                    "title": RESX.ESign.eSign
                }));
        }

        const isDiscarded = item.lifeCycle.value === "discarded";
        if (isDiscarded) {
            td$.addClass("s1_gray");
        }
    },
    _appendTitle: function (tr$, item: server.dto.DocumentForList) {

        const isDraft = item.lifeCycle.value === "draft";
        const isReview = item.lifeCycle.value === "review";
        const isPending = item.lifeCycle.value === "pending";
        const isCompleted = item.lifeCycle.value === "completed";
        const isDiscarded = item.lifeCycle.value === "discarded";

        const overDue = item.overDue;

        var td$ = tr$.appendTd({});

        var a$ = td$.appendDiv().appendA({
            "href": item.detailUrl,
            "text": item.documentNumber
        });

        const subLabelDiv = $("<div />", {
            "class": "s1_small",
            "text": item.lifeCycle.text
        });

        if (isDraft) {
            subLabelDiv.addClass("s1_tag darkgray-bg");
        }

        if (isReview) {
            subLabelDiv.addClass("s1_tag purple-bg");
        }

        if (isPending && !overDue) {
            subLabelDiv.addClass("s1_tag orange-bg");
        }

        if (isPending && overDue) {
            subLabelDiv.addClass("s1_tag red-bg");
        }

        if (isCompleted) {
            subLabelDiv.addClass("s1_tag green-bg");
        }

        if (isDiscarded) {
            a$.addClass("s1_gray");
            subLabelDiv.addClass("s1_tag silver-bg");
        }

        td$.append(subLabelDiv);

    },
    _appendAddressee: function (tr$, item: server.dto.DocumentForList) {

        const isDraft = item.lifeCycle.value === "draft";
        const isReview = item.lifeCycle.value === "review";
        const isPending = item.lifeCycle.value === "pending";
        const isCompleted = item.lifeCycle.value === "completed";
        const isDiscarded = item.lifeCycle.value === "discarded";

        const overDue = item.overDue;

        var td$ = tr$.appendTd({
            "text": item.addressee.name
        });

        if (isDiscarded) {
            td$.addClass("s1_gray");
        }
    },
    _appendSendDueDate: function (tr$, item: server.dto.DocumentForList) {

        const isPending = item.lifeCycle.value === "pending";
        const isCompleted = item.lifeCycle.value === "completed";
        const isDiscarded = item.lifeCycle.value === "discarded";

        const td$ = tr$.appendTd({
            "style": "white-space:nowrap;"
        });

        if (!item.legacy && (!isPending && !isCompleted && !isDiscarded)) return;

        td$.appendDiv({
            "text": `${RESX.DateTime.Send}: ${formatShortDate(moment.utc(item.sendDate))}`
        });

        var dueDiv$ = td$.appendDiv({
            "text": `${RESX.DateTime.Due}: ${formatShortDate(moment.utc(item.dueDate))}`
        });

        if (item.overDue && !isCompleted && !isDiscarded) {
            dueDiv$.addClass("s1_red");
        }

        if (isDiscarded) {
            td$.addClass("s1_gray");
        }
    },
    _appendTotalCell: function (tr$, item: server.dto.DocumentForList) {

        const isDiscarded = item.lifeCycle.value === "discarded";

        var td$ = tr$.appendTd({
            "class": "s1_cell_financialamount",
            "style": "width:75px;"
        });

        if (isDiscarded) {
            td$.addClass("s1_gray");
        }

        if (item.sales && item.sales.amount !== 0) {
            td$.appendDiv({
                "text": item.sales.formatted
            });
        }
    },
});